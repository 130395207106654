<template>
    <a-result status="404" title="404" :sub-title="$t('err.plz404')">
        <template #extra>
            <a-button type="primary" @click="$router.push({name:'home'})">{{$t('err.404')}}</a-button>
        </template>
    </a-result>
</template>

<script>
    export default {

    }
</script>

<style scoped>

</style>